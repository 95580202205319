<template>
    <div>
		<div class="crumbs">
			   <el-breadcrumb separator="/">
				   <el-breadcrumb-item>
					   <i class="el-icon-lx-cascades"></i> 功能列表
				   </el-breadcrumb-item>
			   </el-breadcrumb>
		</div>
		<div class="container">
		    <div class="handle-box">
		      <!--  <el-button
		            type="primary"
		            icon="el-icon-delete"
		            class="handle-del mr10"
		            @click="delAllSelection"
		        >批量删除</el-button> -->
		    <!--    <el-select v-model="query.address" placeholder="地址" class="handle-select mr10">
		            <el-option key="1" label="广东省" value="广东省"></el-option>
		            <el-option key="2" label="湖南省" value="湖南省"></el-option>
		        </el-select> -->
		        <el-input v-model="query.content" placeholder="账号" class="handle-input mr10"></el-input>
		        <el-button type="primary" icon="el-icon-search" @click="search_user">搜索</el-button>
				<!-- <el-button type="primary"  @click="handleSearch">导出EXCLE</el-button> -->
		    </div>
		    <el-table
		        :data="tableData"
		        border
		        class="table"
		        ref="multipleTable"
		        header-cell-class-name="table-header"
		        @selection-change="handleSelectionChange"
		    >
		        <el-table-column type="selection" width="55" align="center"></el-table-column>
		        <el-table-column prop="id" label="id"></el-table-column>
				<!-- <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column> -->
	
				<el-table-column prop="phone" label="账号"></el-table-column>
				
				<el-table-column prop="create_time" label="创建时间"></el-table-column>
				<el-table-column prop="day" label="天数"></el-table-column>
				<el-table-column prop="money" label="金额"></el-table-column>
				<el-table-column prop="operator" label="操作人"></el-table-column>
				<!-- <el-table-column prop="is_vip" label="vip"></el-table-column> -->
				
			
		        
		        <!-- <el-table-column label="操作" width="180" align="center">
		            <template #default="scope">
		                <el-button
		                    type="text"
		                    icon="el-icon-edit"
		                    @click="handleEdit(scope.row)"
		                >开通vip</el-button>
		               <el-button
		                    type="text"
		                    icon="el-icon-delete"
		                    class="red"
		                    @click="handleDelete(scope.$index, scope.row)"
		                >取消vip</el-button>
		            </template>
		        </el-table-column> -->
		    </el-table>
		    <div class="pagination">
		        <el-pagination
		            background
		            layout="total, prev, pager, next"
		            :current-page="query.p"
		            :page-size="query.num_of_page"
		            :total="pageTotal"
		            @current-change="handlePageChange"
		        ></el-pagination>
		    </div>
		</div>
		<el-dialog title="开通vip" v-model="editVisible" width="30%">
		    <el-form ref="form" :model="form" label-width="100px">
		       <el-form-item label="天数">
		            <el-input v-model="form.days"></el-input>
		        </el-form-item>
		        <el-form-item label="金额">
		            <el-input v-model="form.money"></el-input>
		        </el-form-item>
		
				  <!-- <el-radio-group v-model="price_id" @change="radio_change" size="small">
				      <el-radio label="1" >30</el-radio>
				      <el-radio label="2" >90</el-radio>
				</el-radio-group> -->
		    </el-form>
		    <template #footer>
		        <span class="dialog-footer">
		            <el-button @click="editVisible = false">取 消</el-button>
		            <el-button type="primary" @click="add_vip">确 定</el-button>
		        </span>
		    </template>
		</el-dialog>
	</div>
</template>

<script>
import {Subordinate,GetSingleUser,SearchUser,getUserData,tuiTePrice,addVip,deleteVip,twitterOrders} from "../api/index";
	
export default {
    name: "subindex",
	data() {
	    return {
			a_image:'this.src="'+require('../assets/img/img.jpg')+'"',
	        query: {
	            num_of_page:10,
				p:1,
				content:"",
	           
	        },
	        tableData: [],
	        multipleSelection: [],
	        delList: [],
	        editVisible: false,
	        pageTotal: 0,
	        form: {
				days:0,
				money:0,
			},
	        idx: -1,
	        id: -1,
			price_id:1,
			user_id:0,
			user_info:{
				withdraw_all:0,
				profiting:0,
				finish_profit:0,
				distribute_num:0
				
				
			},
			
	    };
	},
	created() {
			// this.get_single();
	        this.getData();
	    },
	    methods: {
	        // 获取 easy-mock 的模拟数据
	        getData() {
	            twitterOrders(this.query).then(res => {
	                console.log(res.data);
	                this.tableData = res.data.results;
	                this.pageTotal = res.data.total;
	            });
	        },
			
			search_user(){
					twitterOrders(this.query).then(res => {
										console.log(res.data);
										this.tableData = res.data.results;
										this.pageTotal = res.data.total;
									});
			},
			get_single(){
				tuiTePrice().then(res => {
				    console.log(res.data);
					
				});
			},
	        // 触发搜索按钮
	        handleSearch() {
	            this.$set(this.query, "pageIndex", 1);
	            this.getData();
	        },
	        // 删除操作
	        handleDelete(index,row) {
	            // 二次确认删除
				console.log("quxiao")
				console.log(row.id)
				this.user_id = row.id
	            this.$confirm("确定要取消吗？", "提示", {
	                type: "warning"
	            })
	                .then(() => {
	                   this.delete_vip()
	                })
	                .catch(() => {});
	        },
	        // 多选操作
	        handleSelectionChange(val) {
	            this.multipleSelection = val;
	        },
	        delAllSelection() {
	            const length = this.multipleSelection.length;
	            let str = "";
	            this.delList = this.delList.concat(this.multipleSelection);
	            for (let i = 0; i < length; i++) {
	                str += this.multipleSelection[i].name + " ";
	            }
	            this.$message.error(`删除了${str}`);
	            this.multipleSelection = [];
	        },
	        // 编辑操作
	        handleEdit(row) {
				this.editVisible=true;
				this.user_id = row.id
	            // this.idx = index;
	            // this.form = row;
	            // this.editVisible = true;
				//  this.$router.push({
				// 'path':'/singlesub',
				// 'name':'singlesub',
				// query:{promote_id:row.promote_id}
				  
				// });

	        },
	        // 保存编辑
	        saveEdit() {
	            this.editVisible = false;
	            this.$set(this.tableData, this.idx, this.form);
	        },
	        // 分页导航
	        handlePageChange(val) {
	            this.query.p=val
	            this.getData();
	        },
			add_vip(){
				console.log(this.user_id)
				console.log(this.price_id)
				var that =this
				addVip({"user_id":this.user_id,"days":this.form.days,"money":this.form.money}).then(res => {
										console.log(res);
										that.editVisible =false
										this.getData()
									});
			},
			
			delete_vip(){
				deleteVip({"user_id":this.user_id}).then(res => {
										console.log(res);
										// that.editVisible =false
										this.getData()
									});
			},
			radio_change(res){
				console.log(res)
				this.price_id = res
			}
	    }
	
};
</script>

<style scoped>
.el-row {
    margin-bottom: 20px;
}

.grid-content {
    display: flex;
    align-items: center;
    height: 80px;
}

.grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 10px;
    color: #999;
}

.grid-num {
    font-size: 20px;
    font-weight: bold;
}

.grid-con-icon {
    font-size: 24px;
    width: 60px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    color: #fff;
}

.grid-con-1 .grid-con-icon {
    background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
    background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
    background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
    color: rgb(242, 94, 67);
}
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 200px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
</style>