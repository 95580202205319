<template>
    <div>
        <el-row :gutter="20" class="mgb20">
                <el-col :span="4">
                    <el-card shadow="hover" :body-style="{ padding: '0px' }">
                        <div class="grid-content grid-con-1">
                            <i class="el-icon-lx-redpacket_fill grid-con-icon"></i>
                            <div class="grid-cont-right">
                                <div class="grid-num">{{user_info.withdraw_all}}</div>
                                <div>总佣金(元)</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="4">
                    <el-card shadow="hover" :body-style="{ padding: '0px' }">
                        <div class="grid-content grid-con-2">
                            <i class="el-icon-lx-recharge grid-con-icon"></i>
                            <div class="grid-cont-right">
                                <div class="grid-num">{{user_info.profiting}}</div>
                                <div>预估佣金(元)</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="4">
                    <el-card shadow="hover" :body-style="{ padding: '0px' }">
                        <div class="grid-content grid-con-3">
                            <i class="el-icon-lx-recharge grid-con-icon"></i>
                            <div class="grid-cont-right">
                                <div class="grid-num">{{user_info.finish_profit}}</div>
                                <div>已经结算佣金(元)</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
				<el-col :span="4">
				    <el-card shadow="hover" :body-style="{ padding: '0px' }">
				        <div class="grid-content grid-con-3">
				            <i class="el-icon-lx-people grid-con-icon"></i>
				            <div class="grid-cont-right">
				                <div class="grid-num">{{user_info.distribute_num}}</div>
				                <div>下级人数</div>
				            </div>
				        </div>
				    </el-card>
				</el-col>
            </el-row>
   
		<div class="crumbs">
			   <el-breadcrumb separator="/">
				   <el-breadcrumb-item>
					   <i class="el-icon-lx-cascades"></i> 下级列表
				   </el-breadcrumb-item>
			   </el-breadcrumb>
		</div>
		<div class="container">
		    <div class="handle-box">
		      <!--  <el-button
		            type="primary"
		            icon="el-icon-delete"
		            class="handle-del mr10"
		            @click="delAllSelection"
		        >批量删除</el-button> -->
		    <!--    <el-select v-model="query.address" placeholder="地址" class="handle-select mr10">
		            <el-option key="1" label="广东省" value="广东省"></el-option>
		            <el-option key="2" label="湖南省" value="湖南省"></el-option>
		        </el-select> -->
		        <el-input v-model="query.phone" placeholder="手机号" class="handle-input mr10"></el-input>
		        <el-button type="primary" icon="el-icon-search" @click="search_user">搜索</el-button>
				<!-- <el-button type="primary"  @click="handleSearch">导出EXCLE</el-button> -->
		    </div>
		    <el-table
		        :data="tableData"
		        border
		        class="table"
		        ref="multipleTable"
		        header-cell-class-name="table-header"
		        @selection-change="handleSelectionChange"
		    >
		        <el-table-column type="selection" width="55" align="center"></el-table-column>
		        <el-table-column prop="promote_id" label="推广id"></el-table-column>
				<!-- <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column> -->
		       <el-table-column label="头像(查看大图)" align="center">
		           <template #default="scope">
					   <img style="width: 50px;height: 50px;" v-if="scope.row.avatar" :src="scope.row.avatar"/>
					   <img style="width: 50px;height: 50px;" v-if="scope.row.avatar==null" src='../assets/img/image.png'/>
		             <!--  <el-image
		                   class="table-td-thumb"
		                   :src="scope.row.avatar"
		                   :preview-src-list="[scope.row.avatar]">
						   <div slot="error" class="image-slot">
						     <i class="el-icon-picture-outline"></i>
						   </div>
					   </el-image> -->
					   <!-- <el-image>
					         <div slot="error" class="image-slot">
					           <i class="el-icon-picture-outline"></i>
					         </div>
					       </el-image> -->
						   <!-- https://cdn.yiquhudong.com/others/56a78e11-b2e7-45a4-b18e-a79f62db36ff.png -->
		           </template>
		       </el-table-column>
				<el-table-column prop="phone" label="手机号"></el-table-column>
				
				<el-table-column prop="wx_id" label="微信号"></el-table-column>
				<el-table-column prop="creat_time" label="注册时间"></el-table-column>
				<el-table-column prop="last_time" label="最新佣金时间"></el-table-column>
		        <el-table-column label="佣金收益">
		            <template #default="scope">￥{{ scope.row.subordinate_profit }}</template>
		        </el-table-column>
		       
		        <el-table-column label="操作" width="180" align="center">
		            <template #default="scope">
		                <el-button
		                    type="text"
		                    icon="el-icon-edit"
		                    @click="handleEdit(scope.row)"
		                >查看详情</el-button>
		               <!-- <el-button
		                    type="text"
		                    icon="el-icon-delete"
		                    class="red"
		                    @click="handleDelete(scope.$index, scope.row)"
		                >删除</el-button> -->
		            </template>
		        </el-table-column>
		    </el-table>
		    <div class="pagination">
		        <el-pagination
		            background
		            layout="total, prev, pager, next"
		            :current-page="query.p"
		            :page-size="query.num_of_page"
		            :total="pageTotal"
		            @current-change="handlePageChange"
		        ></el-pagination>
		    </div>
		</div>
		
	</div>
</template>

<script>
import {Subordinate,GetSingleUser,SearchUser} from "../api/index";
	
export default {
    name: "subindex",
	data() {
	    return {
			a_image:'this.src="'+require('../assets/img/img.jpg')+'"',
	        query: {
	            num_of_page:40,
				p:1,
				phone:"",
	           
	        },
	        tableData: [],
	        multipleSelection: [],
	        delList: [],
	        editVisible: false,
	        pageTotal: 0,
	        form: {},
	        idx: -1,
	        id: -1,
			user_info:{
				withdraw_all:0,
				profiting:0,
				finish_profit:0,
				distribute_num:0
				
				
			},
			
	    };
	},
	created() {
			this.get_single();
	        this.getData();
	    },
	    methods: {
	        // 获取 easy-mock 的模拟数据
	        getData() {
	            Subordinate(this.query).then(res => {
	                console.log(res.data);
	                this.tableData = res.data.results;
	                this.pageTotal = res.data.total;
	            });
	        },
			
			search_user(){
					SearchUser(this.query).then(res => {
										console.log(res.data);
										this.tableData = res.data.results;
										this.pageTotal = res.data.total;
									});
			},
			get_single(){
				GetSingleUser().then(res => {
				    console.log(res.data);
					this.user_info.withdraw_all=res.data.distributeprofit.withdraw_all
					this.user_info.profiting=res.data.distributeprofit.profiting
					this.user_info.finish_profit=res.data.distributeprofit.finish_profit
					this.user_info.distribute_num=res.data.distributeuserinfo.distribute_num
					
				});
			},
	        // 触发搜索按钮
	        handleSearch() {
	            this.$set(this.query, "pageIndex", 1);
	            this.getData();
	        },
	        // 删除操作
	        handleDelete(index) {
	            // 二次确认删除
	            this.$confirm("确定要删除吗？", "提示", {
	                type: "warning"
	            })
	                .then(() => {
	                    this.$message.success("删除成功");
	                    this.tableData.splice(index, 1);
	                })
	                .catch(() => {});
	        },
	        // 多选操作
	        handleSelectionChange(val) {
	            this.multipleSelection = val;
	        },
	        delAllSelection() {
	            const length = this.multipleSelection.length;
	            let str = "";
	            this.delList = this.delList.concat(this.multipleSelection);
	            for (let i = 0; i < length; i++) {
	                str += this.multipleSelection[i].name + " ";
	            }
	            this.$message.error(`删除了${str}`);
	            this.multipleSelection = [];
	        },
	        // 编辑操作
	        handleEdit(row) {
	            // this.idx = index;
	            // this.form = row;
	            // this.editVisible = true;
				 this.$router.push({
				'path':'/singlesub',
				'name':'singlesub',
				query:{promote_id:row.promote_id}
				  
				});

	        },
	        // 保存编辑
	        saveEdit() {
	            this.editVisible = false;
	            this.$set(this.tableData, this.idx, this.form);
	        },
	        // 分页导航
	        handlePageChange(val) {
	            this.query.p=val
	            this.getData();
	        }
	    }
	
};
</script>

<style scoped>
.el-row {
    margin-bottom: 20px;
}

.grid-content {
    display: flex;
    align-items: center;
    height: 80px;
}

.grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 10px;
    color: #999;
}

.grid-num {
    font-size: 20px;
    font-weight: bold;
}

.grid-con-icon {
    font-size: 24px;
    width: 60px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    color: #fff;
}

.grid-con-1 .grid-con-icon {
    background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
    background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
    background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
    color: rgb(242, 94, 67);
}
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 200px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
</style>