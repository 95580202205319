<template>
    <div>
		<div class="crumbs">
			   <el-breadcrumb separator="/">
				   <el-breadcrumb-item>
					   <i class="el-icon-lx-cascades"></i> cpdd提现列表
				   </el-breadcrumb-item>
			   </el-breadcrumb>
		</div>
		<div class="container">
		    <div class="handle-box">
		      <!--  <el-button
		            type="primary"
		            icon="el-icon-delete"
		            class="handle-del mr10"
		            @click="delAllSelection"
		        >批量删除</el-button> -->
		    <!--    <el-select v-model="query.address" placeholder="地址" class="handle-select mr10">
		            <el-option key="1" label="广东省" value="广东省"></el-option>
		            <el-option key="2" label="湖南省" value="湖南省"></el-option>
		        </el-select> -->
				<!-- <el-button type="primary"  @click="editVisible=true">新增功能</el-button> -->
		   <el-button type="primary"  @click="wite_withdraw">待提现</el-button>
		   <el-button type="primary"  @click="all_withdraw">全部提现</el-button>
			</div>
		    <el-table
		        :data="tableData"
		        border
		        class="table"
		        ref="multipleTable"
		        header-cell-class-name="table-header"
		        @selection-change="handleSelectionChange"
		    >
		        <el-table-column type="selection" width="55" align="center"></el-table-column>
		        <el-table-column prop="id" width="80" label="id"></el-table-column>
				<!-- <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column> -->
	
				<el-table-column prop="uid" width="100" label="UID"></el-table-column>
				
				<el-table-column prop="nickname" label="微信昵称"></el-table-column>
				<el-table-column prop="weixin" label="微信号"></el-table-column>
				
				<el-table-column prop="channel_realname" label="真实姓名"></el-table-column>
				<el-table-column prop="mobile" label="手机号"></el-table-column>
				
				<el-table-column align="center" label="提现金额">
					<template #default="scope">
						
						<el-tag type="success" style="font-size: 15px;" >{{scope.row.money}}</el-tag>	    
					</template>
				</el-table-column>
				
				<el-table-column label="提现方式" align="center">
		            <template #default="scope">
						<el-tag v-if="scope.row.channel ===1" type="danger" effect="plain">微信打款</el-tag>
						<el-tag v-if="scope.row.channel ===3" type="success" effect="plain" >支付宝转账</el-tag>
		    
		            </template>
		        </el-table-column>	
			
				<el-table-column label="提现账号" align="center">
				    <template #default="scope">
						<!-- <img style="width: 80px;height: 80px;" v-if="scope.row.channel ===1"  :src='scope.row.channel_account' /> -->
						<el-image
							
							v-if="scope.row.channel ===1"
						    style="width: 80px; height: 80px"
						    :src="scope.row.channel_account"
						    :fit="fit"
							:preview-src-list="srcList"
							@click="fdimage(scope.row.channel_account)"
							></el-image>
						<!-- <el-tag v-if="scope.row.channel ===1" type="danger" >{{scope.row.channel_account}}</el-tag> -->
						<el-tag v-if="scope.row.channel ===3" type="success" >{{scope.row.channel_account}}</el-tag>
						    
				    </template>
				</el-table-column>	
				
				<el-table-column label="提现状态" align="center">
				    <template #default="scope">
						<el-tag v-if="scope.row.status ===0" type="danger" effect="plain">待打款</el-tag>
						<el-tag v-if="scope.row.status ===1" type="success" effect="plain" >已打款</el-tag>
						    
				    </template>
				</el-table-column>	
							
				<el-table-column prop="create_time" label="提现时间"></el-table-column>
				
				<!-- <el-table-column prop="is_vip" label="vip"></el-table-column> -->
				
			
		        
		        <el-table-column label="操作" width="180" align="center">
		            <template #default="scope">
		                <el-button
							v-if="scope.row.channel ===3 && scope.row.status !='1'"
		                    type="text"
		                    icon="el-icon-edit"
		                    @click="zfb_withdraw(scope.row)"
		                >支付宝打款</el-button>
		               <el-button
							v-if="scope.row.status !='1'"
		                    type="text"
		                    class="red"
		                    @click="withcash(scope.row)"
		                >打款</el-button>
		            </template>
		        </el-table-column>
		    </el-table>
		    <div class="pagination">
		        <el-pagination
		            background
		            layout="total, prev, pager, next"
		            :current-page="query.p"
		            :page-size="query.num_of_page"
		            :total="pageTotal"
		            @current-change="handlePageChange"
		        ></el-pagination>
		    </div>
		</div>
		<el-dialog title="提现确认" v-model="editVisible" width="30%">
		    <el-form ref="form" :model="form" label-width="100px">
		       <el-form-item label="id">
		            <el-input :disabled="true"  v-model="form.id"></el-input>
		        </el-form-item>
				<el-form-item label="用户id">
					      <el-input :disabled="true" v-model="form.uid"></el-input>
					  </el-form-item>
				<el-form-item label="提现金额">
						<el-input  v-model="form.money"></el-input>
				</el-form-item>
				<el-form-item label="用户名">
					  <el-input :disabled="true"  v-model="form.name"></el-input>
				  </el-form-item>
		        <el-form-item label="标识">
		            <el-input v-model="form.remark"></el-input>
		        </el-form-item>
		

		    </el-form>
		    <template #footer>
		        <span class="dialog-footer">
		            <el-button @click="editVisible = false">取 消</el-button>
		            <el-button type="primary" @click="confirm_tranfer">确 定</el-button>
		        </span>
		    </template>
		</el-dialog>
		<el-dialog title="支付宝打款" v-model="zfb_editVisible" width="30%">
		    <el-form ref="form" :model="zfb_form" label-width="100px">
		       <el-form-item label="id">
		            <el-input :disabled="true"  v-model="zfb_form.id"></el-input>
		        </el-form-item>
				<el-form-item label="用户id">
					      <el-input :disabled="true" v-model="zfb_form.uid"></el-input>
					  </el-form-item>
				<el-form-item label="提现金额">
						<el-input v-model="zfb_form.money"></el-input>
				</el-form-item>
				<el-form-item label="用户名">
					  <el-input :disabled="true"  v-model="zfb_form.name"></el-input>
				  </el-form-item>
				<el-form-item label="支付宝账号">
				    <el-input v-model="zfb_form.account"></el-input>
				</el-form-item>
		        <el-form-item label="标识">
		            <el-input v-model="zfb_form.remark"></el-input>
		        </el-form-item>
				
		
		    </el-form>
		    <template #footer>
		        <span class="dialog-footer">
		            <el-button @click="zfb_editVisible = false">取 消</el-button>
		            <el-button type="primary" @click="zfb_tranfer">确 定</el-button>
		        </span>
		    </template>
		</el-dialog>
	</div>
</template>

<script>
import {AddtwitterFunctions,getCPDDUserData,getCPDDConfirm_tanfer,getCPDDTranfer} from "../../api/index";
	
export default {
    name: "cpdd_outcash",
	data() {
	    return {
	        query: {
	            num_of_page:10,
				p:1,
				status:1,
	           
	        },
	        tableData: [],
	        multipleSelection: [],
	        delList: [],
	        editVisible: false,
			zfb_editVisible:false,
	        pageTotal: 0,
	        form: {
				id:'',
				money:'',
				name:'',
				uid:'',
				remark:'已发放',
			},
			zfb_form:{
				id:'',
				money:'',
				name:'',
				uid:'',
				remark:'已发放',
				account:''
			},
	        idx: -1,
	        id: -1,
			dis:0,
			srcList: [
			        ]
			
	    };
	},
	created() {
	        this.getData();
	    },
	    methods: {
	        getData() {
	            getCPDDUserData(this.query).then(res => {
	                console.log(res.data);
	                this.tableData = res.data.results;
	                this.pageTotal = res.data.total;
	            });
	        },
			search_user(){		
			},
			all_withdraw(){
				this.query.status = 1,
				this.getData()
			},
			wite_withdraw(){
				this.query.status = 0,
				this.getData()
			},
	        // 触发搜索按钮
	        handleSearch() {
	            this.$set(this.query, "pageIndex", 1);
	            this.getData();
	        },
	        // 删除操作
	        handleDelete(index,row) {
	            // 二次确认删除
				console.log("quxiao")
				console.log(row.id)
				this.user_id = row.id
	            this.$confirm("确定要取消吗？", "提示", {
	                type: "warning"
	            })
	                .then(() => {
	                   this.delete_vip()
	                })
	                .catch(() => {});
	        },
	        // 多选操作
	        handleSelectionChange(val) {
	            this.multipleSelection = val;
	        },
	        delAllSelection() {
	            const length = this.multipleSelection.length;
	            let str = "";
	            this.delList = this.delList.concat(this.multipleSelection);
	            for (let i = 0; i < length; i++) {
	                str += this.multipleSelection[i].name + " ";
	            }
	            this.$message.error(`删除了${str}`);
	            this.multipleSelection = [];
	        },
	        // 编辑操作
	        handleEdit(row) {
				this.editVisible=true;
				this.user_id = row.id
	            // this.idx = index;
	            // this.form = row;
	            // this.editVisible = true;
				//  this.$router.push({
				// 'path':'/singlesub',
				// 'name':'singlesub',
				// query:{promote_id:row.promote_id}
				  
				// });

	        },
			withcash(row){
				this.editVisible =true
				this.form = {
					id:row.id,
					money:row.money,
					name:row.channel_realname,
					uid:row.uid,
					remark:'已发放',
				}
			},
			zfb_withdraw(row){
				console.log(row)
				this.zfb_editVisible =true
				this.zfb_form = {
					id:row.id,
					money:row.money,
					name:row.channel_realname,
					uid:row.uid,
					remark:'已发放',
					account:row.channel_account
				}
				console.log(this.zfb_form)
			},
	        // 保存编辑
	        saveEdit() {
	            this.editVisible = false;
	            this.$set(this.tableData, this.idx, this.form);
	        },
	        // 分页导航
	        handlePageChange(val) {
	            this.query.p=val
	            this.getData();
	        },
			fdimage(val){
				this.srcList = [val]
			},
			confirm_tranfer(){
				getCPDDConfirm_tanfer(this.form).then(res=>{
					if(res.code==0){
						this.$message(res.data);
					}else{
						this.$message(res.msg);
					}
					console.log(res)
					this.editVisible=false
					this.getData();
				})
			},
			zfb_tranfer(){
				if(this.dis !=0){
					console.log("不可点击")
					return
				};
				this.dis =1;
				getCPDDTranfer(this.zfb_form).then(res=>{
					if(res.code==0){
						this.$message(res.data);
					}else{
						this.$message(res.msg);
					}
					console.log(res)
					this.zfb_editVisible=false
					this.getData();
					this.dis = 0;
				})
			}

	    }
	
};
</script>

<style scoped>
.el-row {
    margin-bottom: 20px;
}

.grid-content {
    display: flex;
    align-items: center;
    height: 80px;
}

.grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 10px;
    color: #999;
}

.grid-num {
    font-size: 20px;
    font-weight: bold;
}

.grid-con-icon {
    font-size: 24px;
    width: 60px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    color: #fff;
}

.grid-con-1 .grid-con-icon {
    background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
    background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
    background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
    color: rgb(242, 94, 67);
}
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 200px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
</style>